<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="page-content-header"></div>
<div class="container">
  <div class="page-content page-content-overlay">
    <h1>Politique "cookies"</h1>
    <h2>Quelle est l&rsquo;utilit&eacute; de cette politique ?</h2>
    <p>
      <strong
        >Nous accordons une grande importance &agrave; la confidentialit&eacute;
        de vos donn&eacute;es qui repr&eacute;sentent pour nous un gage de
        s&eacute;rieux et de confiance, peu importe qu&rsquo;il s&rsquo;agisse
        de &ldquo;cookies&rdquo; ou de donn&eacute;es personnelles.</strong
      >
    </p>
    <p>
      La politique &ldquo;cookies&rdquo; vise &agrave; vous expliquer pourquoi
      nous utilisons des <b>&ldquo;Cookies&rdquo;</b> lorsque vous naviguez sur
      notre site internet.
    </p>

    <h2>Qu'est-ce qu&rsquo;un &ldquo;cookies&rdquo; ?</h2>
    <p>
      Les &ldquo;cookies&rdquo; sont des
      <b>petits fichiers &ldquo;texte&rdquo;</b> qui sont plac&eacute;s et
      h&eacute;berg&eacute;s sur votre <b>smartphone</b>, <b>tablette</b>, ou
      <b>ordinateur</b>, afin de vous permettre de naviguer sur notre site
      internet.
    </p>
    <h2>
      &Agrave; quoi servent pr&eacute;cis&eacute;ment des &ldquo;cookies&rdquo;
      ?
    </h2>

    <p>
      Les <b>&ldquo;cookies&rdquo;</b> permettent de
      <b>garder en m&eacute;moire</b> certaines informations vous concernant
      afin de vous <b>reconna&icirc;tre</b> lorsque vous vous connectez de
      nouveau sur notre site internet et de
      <b>mieux comprendre</b> l&rsquo;utilisation que vous faites lorsque vous
      naviguez dessus afin de vous proposer un <b>service de qualit&eacute;</b>.
    </p>

    <h2>
      Quelles cat&eacute;gories de &ldquo;cookies&rdquo; utilisons-nous et pour
      combien de temps ?
    </h2>
    <p>
      Nous utilisons uniquement des &ldquo;cookies&rdquo;
      <b>n&eacute;cessaires au bon fonctionnement</b> de notre site internet
      ainsi que des "cookies" qui fournissent des
      <b>donn&eacute;es statistiques</b> relatives &agrave; votre
      fr&eacute;quentation et &agrave; votre utilisation de notre site internet.
    </p>
    <p>
      Nous vous garantissons d'ailleurs que
      <b>nous n'utilisons pas de "cookies" de tra&ccedil;abilit&eacute;</b>
      ayant vocation &agrave; conna&icirc;tre votre navigation sur d'autres
      sites internet ou &agrave; vous fournir de la publicit&eacute;
      cibl&eacute;e.
    </p>

    <h2>Quel contr&ocirc;le avez-vous sur nos &ldquo;cookies&rdquo; ?</h2>
    <p>
      Les &ldquo;cookies&rdquo; n&eacute;cessaires au bon fonctionnement de
      notre site internet
      <b>ne peuvent pas &ecirc;tre param&eacute;tr&eacute;s</b> car leur absence
      aurait un trop grand impact sur votre exp&eacute;rience. En revanche,
      votre <b>consentement</b> est toujours requis pour que nous puissions
      utiliser des cookies statistiques.
    </p>

    <h2>Qui pouvez-vous contacter pour obtenir plus d&rsquo;informations ?</h2>
    <p>
      Notre
      <b
        >D&eacute;l&eacute;gu&eacute; &agrave; la protection des donn&eacute;es
        (&ldquo;DPO&rdquo;)</b
      >
      est toujours &agrave; votre disposition pour vous expliquer plus en
      d&eacute;tail la mani&egrave;re dont nous traitons vos donn&eacute;es et
      pour r&eacute;pondre &agrave; vos questions sur le sujet &agrave;
      l&rsquo;adresse
      <a href="mailto:rgpd@solinum.org">rgpd&#64;solinum.org</a>.
    </p>

    <h2>Comment pouvez-vous contacter la CNIL ?</h2>
    <p>
      Vous pouvez &agrave; tout moment contacter l&rsquo;autorit&eacute; de
      contr&ocirc;le fran&ccedil;aise en mati&egrave;re de protection des
      donn&eacute;es (la &ldquo;<b
        >Commission nationale de l'informatique et des libert&eacute;s</b
      >&rdquo; ou &ldquo;<b>CNIL</b>&rdquo;) aux coordonn&eacute;es suivantes :
      Service des plaintes de la CNIL, 3 place de Fontenoy &ndash; TSA 80751,
      75334 Paris Cedex 07 ou par t&eacute;l&eacute;phone au
      <a href="tel:+33153732222">+33(0)1.53.73.22.22</a>.
    </p>

    <h2>La politique peut-elle &ecirc;tre modifi&eacute;e ?</h2>
    <p>
      Nous sommes susceptibles de <b>modifier</b> notre politique
      &ldquo;Cookies&rdquo; &agrave; <b>tout moment</b> pour l&rsquo;adapter aux
      nouvelles <b>exigences l&eacute;gales</b> ainsi qu&rsquo;aux
      <b>nouveaux traitements</b> que nous pourrions mettre en &oelig;uvre dans
      le futur. Vous serez &eacute;videmment inform&eacute;s de toute
      modification de cette politique.
    </p>
    <p>
      <br />
      Publi&eacute; le 01/02/2022
    </p>
    <p>
      <em>
        Certifiée conforme par
        <a href="https://www.dipeeo.com/"><u>Dipeeo</u></a> &reg;
      </em>
    </p>
  </div>
</div>
