<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="container my-4" *ngIf="!success && signupForm">
  <div class="row">
    <div class="col-md-8 offset-md-2 my-2">
      <h1 class="title">
        {{ "CREER_COMPTE_TRAD" | translate }}
      </h1>
      <div class="limite"></div>
    </div>

    <form
      [formGroup]="signupForm"
      class="col-md-8 offset-md-2"
      (ngSubmit)="signup()"
    >
      <div class="form-container">
        <div class="row">
          <div class="col-md-6 my-2 required">
            <label for="name" class="form-label">
              {{ "PSEUDO" | translate }}
            </label>
            <input
              id="name"
              type="text"
              class="form-control"
              formControlName="name"
              autocomplete="name"
              [placeholder]="'PSEUDO' | translate"
              [ngClass]="{
                'is-invalid': (f.name.dirty || submitted) && f.name.errors,
                'is-valid': f.name.dirty && f.name.value && !f.name.errors
              }"
            />
            <div
              *ngIf="(f.name.dirty || submitted) && f.name.errors"
              class="invalid-feedback"
            >
              {{ "CHOOSE_NICKNAME" | translate }}
            </div>
          </div>
          <div class="col-md-6 my-2 required">
            <label for="listLanguages" class="form-label">{{
              "LISTE_LANGUES" | translate
            }}</label>
            <div
              class="custom-dropdown"
              [ngClass]="{
                'is-invalid-languages is-invalid':
                  (f.languages.touched || submitted) && f.languages.errors,
                'is-valid-languages is-invalid':
                  f.languages.touched &&
                  f.languages.value &&
                  !f.languages.errors
              }"
              placement="bottom"
              ngbDropdown
              autoClose="outside"
            >
              <button type="button" id="listLanguages" ngbDropdownToggle>
                <span class="text-truncate"> {{ getStringToDisplay() }}</span>
              </button>

              <div ngbDropdownMenu>
                <button
                  *ngFor="let language of SUPPORTED_LANGUAGES; let i = index"
                  type="button"
                  (click)="toggleCheckboxButton(language)"
                  ngbDropdownItem
                >
                  <input
                    [checked]="f?.languages?.value?.includes(language)"
                    type="checkbox"
                  />
                  <span>{{
                    "LANGUE_" + language | uppercase | translate
                  }}</span>
                </button>
              </div>
            </div>
            <div
              *ngIf="(f.languages.touched || submitted) && f.languages.errors"
              class="invalid-feedback-custom"
            >
              {{ "CHOOSE_LANGUAGE" | translate }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 my-2 required">
            <label for="email" class="form-label">{{
              "EMAIL_ADDRESS" | translate
            }}</label>
            <input
              id="email"
              type="email"
              formControlName="mail"
              class="form-control"
              autocomplete="email"
              [placeholder]="'EMAIL_ADDRESS' | translate"
              [ngClass]="{
                'is-invalid': (submitted || f.mail.dirty) && f.mail.errors,
                'is-valid': f.mail.dirty && f.mail.value && !f.mail.errors
              }"
            />
            <div
              *ngIf="(submitted || f.mail.dirty) && f.mail.errors"
              class="invalid-feedback"
            >
              <p *ngIf="f.mail.errors.invalidEmail">
                {{ "INCORRECT_EMAIL_ADDRESS" | translate }}
              </p>
              <p *ngIf="f.mail.errors.required">
                {{ "EMAIL_REQUIRED" | translate }}
              </p>
              <p *ngIf="f.mail.errors.emailTaken">
                {{ "UNUSED_EMAIL_ADDRESS" | translate }}
              </p>
            </div>
          </div>
        </div>

        <app-user-password-form
          [parentFormGroup]="signupForm"
          [submitted]="submitted"
        ></app-user-password-form>

        <div class="row mt-2">
          <div class="cgu col-12 mt-4 my-2 required">
            <div
              class="label-cbx d-flex"
              [ngClass]="{
                'is-invalid': (f.cgu.dirty || submitted) && f.cgu.errors
              }"
            >
              <input
                id="cgu"
                type="checkbox"
                formControlName="cgu"
                class="invisible"
                [checked]="f.cgu.value"
              />
              <div class="checkbox">
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 20 20"
                  (click)="f.cgu.setValue(!f.cgu.value)"
                >
                  <path
                    d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"
                  ></path>
                  <polyline points="4 11 8 15 16 6"></polyline>
                </svg>
              </div>

              <div
                [ngClass]="{
                  'text-danger': (f.cgu.dirty || submitted) && f.cgu.errors
                }"
                [innerHTML]="
                  'ACCEPT_GCU_WITH_LINK'
                    | translate : { routePrefix }
                    | safeHtml
                "
              ></div>
            </div>

            <div
              *ngIf="
                (f.cgu.dirty || submitted) && (f.cgu.errors || f.cgu.invalid)
              "
              class="invalid-feedback"
            >
              <div *ngIf="f.cgu.errors?.required">
                {{
                  "GCU_REQUIRED"
                    | translate : { brandName: THEME_CONFIGURATION.brandName }
                }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <button
          type="submit"
          aria-label="Inscription"
          [disabled]="loading"
          class="btn btn-lg btn-primary"
        >
          <span *ngIf="!loading">{{ "CREATE_YOUR_ACCOUNT" | translate }}</span>

          <span *ngIf="loading"
            ><fa-icon
              aria-hidden="true"
              [icon]="['fas', 'circle-notch']"
              [spin]="true"
            ></fa-icon
            >{{ "REGISTRATION_IN_PROGRESS" | translate }}</span
          >
        </button>
      </div>
    </form>
  </div>
</div>
