<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="page-content-header"></div>
<div class="container">
  <div class="page-content page-content-overlay">
    <h1>Política de cookies</h1>

    <h2>Quina és la utilitat d'aquesta política?</h2>
    <p>
      <strong>
        Solinum, qui dirigeix la web:
        <a href="https://soliguia.cat">soliguia.cat</a>
        (&ldquo;Soliguia&rdquo;), acorda una gran importància a la
        confidencialitat de les vostre dades que representen per a nosaltres una
        garantia de serietat i de confiança, poc ens importa que es tracti de
        cookies ou de dades personals.
      </strong>
    </p>
    <p>
      La política de <b>cookies</b> us explica perquè utilitzem cookies i altres
      tipus de <b>marcadors</b> quan navegueu per la nostra plataforma
      Soliguide.
    </p>
    <p>
      Per contra, aquesta política de cookies només afecta els tractaments
      relatius <b>al depòsit de cookies i marcadors</b>. Per els altres
      tractaments que fem, podeu consultar
      <b
        >la nostra política de confidencialitat de dades, accessible en tot
        moment</b
      >
      a la plataforma Soliguide.
    </p>

    <h2>Què és una galeta i quina és la seva utilitat?</h2>
    <p>
      Les cookies i altres marcadors són
      <b>petits fitxers &ldquo;text&rdquo;</b> col·locats i allotjats en un
      servidor al vostre <b>telèfon, tableta o ordinador</b> quan consulteu una
      pàgina o lloc web.
    </p>
    <p>
      Les cookies <b>no permeten identificar-vos personalment</b>, sinó
      identificar el vostre terminal (ex: telèfon, tableta, etc.) mitjançant la
      vostra adreça IP. Així mateix, reben altres informacions relacionades amb
      la vota navegació (ex: durada de navegació, pàgines consultades, etc..)
      per a:
    </p>
    <ul>
      <li>
        memoritzar el vostre identificador per a reconèixer-vos quan us
        connecteu novament a una web i conservar les vostres opcions (ex:
        llengües, rebuig de cookies, conservar la vostra cistella, etc...).
      </li>
      <li>
        traçar i analitzar l'ús que feu d'una pàgina o lloc web amb finalitats
        estadístiques per a millorar la vostra experiència i oferir-vos un
        servei adaptat a les vostres necessitats.
      </li>
      <li>
        permetre navegar i utilitzar una pàgina o lloc web en òptimes
        condicions.
      </li>
      <li>
        analitzar l'ús que feu d'una pàgina o lloc web per a oferir-vos la
        publicitat adaptada a les vostres necessitats en aquest o en d’altres
        llocs web.
      </li>
    </ul>
    <p>
      Per a més informació sobre aquest tema, podeu consultar els llocs web
      següents:
      <a href="www.cnil.fr">www.cnil.fr</a>,
      <a href="www.allaboutcookies.org">www.allaboutcookies.org</a> i
      <a href="www.youronlinechoices.eu">www.youronlinechoices.eu</a>.
    </p>

    <h2>Per què utilitzem les &ldquo;cookies&rdquo; i per quant de temps ?</h2>
    <p>
      Quan us connecteu a la nostra plataforma Soliguide, estem obligats a
      depositar les nostres cookies en el vostre terminal <b>només</b> per les
      <b>raons següents</b>:
    </p>
    <li>
      <b>Cookies tècniques:</b> que us permeten utilitzar els nostres serveis
      Soliguide amb tota seguretat sobre la base del nostre interès legítim a
      oferir-vos un servei de qualitat.
    </li>
    <li>
      <b>Cookies estadístiques:</b> que permeten conèixer l'ús que feu de la
      nostra pàgina (ex: durada del visionat d'una pàgina, pàgines consultades,
      etc..) sobre la base del vostre consentiment previ, per oferir-vos un
      servei adaptat i de qualitat.
    </li>
    <p>
      En tots els casos, les cookies depositades, sigui quina sigui la seva
      naturalesa, es conserven <b>durant 13 mesos màxim</b> a partir de la seva
      instal·lació i només es renoven en cas de noves connexions en la nostra
      web un cop transcorregut el termini. Si voleu més informació sobre la
      durada d’utilització de les nostres cookies, no dubteu a contactar-nos a
      l'adreça <a href="mailto:dipeeo@solinum.org">dipeeo&#64;solinum.org</a>.
    </p>

    <h2>Quin control teniu sobre aquestes &ldquo;cookies&rdquo; ?</h2>
    <p>
      No us podeu <b>oposar al depòsit de cookies tècniques</b>, perquè són
      necessàries per al bon funcionament de la nostra web que si no seria
      potencialment inutilitzable.
    </p>
    <p>
      Com s'ha precisat anteriorment, aquesta excepció no s'aplica a les cookies
      tècniques depositades sobre el vostre terminal per fer anar els
      <b>vídeos</b>. En aquest cas concret, el depòsit de cookies no és possible
      més si vosaltres no ho heu <b>consentit</b> prèviament.
    </p>
    <p>
      Tanmateix, a més dels drets detallats en la nostra política de
      confidencialitat, disposeu evidentment del dret de
      <b>rebutjar o de retirar el vostre consentiment</b> a la instal·lació en
      el vostre terminal de tots els altres tipus de cookies.
    </p>
    <p>
      El dret a rebutjar les cookies es fa en qualsevol moment utilitzant el
      nostre banner de cookies. En cas que el banner de cookies no aparegui més,
      podeu igualment configurar les cookies directament en el vostre navegador
      seguint les següents instruccions:
      <b
        ><a
          href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en%20%22Chrome%22"
          >Chrome</a
        ></b
      >,
      <b
        ><a
          href="https://learn.microsoft.com/fr-fr/microsoft-edge/devtools-guide-chromium/storage/cookies"
          >Microsoft Edge</a
        ></b
      >,
      <b
        ><a
          href="https://support.apple.com/fr-fr/guide/safari/sfri11471/16.0/mac/11.0"
          >Safari</a
        ></b
      >,
      <b
        ><a
          href="https://support.mozilla.org/fr/kb/effacer-cookies-donnees-site-firefox"
          >Firefox</a
        ></b
      >
      i
      <b
        ><a
          href="https://blogs.opera.com/tips-and-tricks/2023/04/clean-browser-and-remove-trackers/"
          >Opera</a
        ></b
      >.
    </p>

    <h2>A qui podeu contactar per obtenir més informació ?</h2>
    <p>
      Podeu contactar, a qualsevol moment i gratuïtament, el nostre DPO a
      l’e-mail
      <a href="mailto:dipeeo@solinum.org">dipeeo&#64;solinum.org</a> per obtenir
      més informació o detalls sobre la manera amb què nosaltres tractem les
      vostres dades.
    </p>
    <p>
      Per garantir millor la protecció i integritat de les vostres dades, hem
      designat
      <b
        >un delegat per a la protecció de dades (&ldquo;DPO&rdquo;)
        independent</b
      >
      davant la nostra autoritat de control.
    </p>

    <h2>Com podeu contactar la CNIL ?</h2>
    <p>
      Podeu contactar la
      <b
        >&ldquo;Comissió nacional d'informàtica i de les llibertats&rdquo; o
        &ldquo;CNIL&rdquo;</b
      >
      a qualsevol moment a l’adreça següent: Service des plaintes (Servei de
      quaiexes) de la CNIL, Place de Fontenoy 3- TSA 80751, 75334 París, Cedex
      07 o per telèfon al <a href="tel:+33153732222">+33(0)1.53.73.22.22</a>.
    </p>

    <h2>La política, pot modificar-se ?</h2>
    <p>
      Podem modificar la nostra política de cookiescookies a qualsevol moment
      per adaptar-la a les noves exigències legals, així com als nous
      tractaments que nosaltres poguéssim implementar en el futur. Evidentment,
      us informarem de qualsevol modificació d'aquesta política.
    </p>

    <p>
      <em
        >Conforme a l’original per
        <a href="https://www.dipeeo.com/"><u>Dipeeo</u></a> &reg;</em
      >
    </p>
  </div>
</div>
