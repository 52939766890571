<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="row align-items-center">
  <div class="col-md-3 p-4">
    <img
      class="img-fluid"
      src="assets/images/search/no_results.svg"
      alt="Aucun résultat de recherche"
    />
  </div>
  <div class="col-md-9">
    <h2 class="text-primary">{{ "SEARCH_NO_RESULT_TITLE" | translate }}</h2>
    <h3>{{ "SEARCH_NO_RESULT_DESCRIPTION" | translate }}</h3>
    <br />
    <p>
      <span class="me-2">💬</span>{{ "CONTACT_US_WITH_CHAT_1" | translate }}
      <span
        class="link-secondary"
        (click)="openChat(); captureEvent('with-the-chat')"
        >{{ "CONTACT_US_WITH_CHAT_2" | translate }}</span
      >,
      {{ "CONTACT_US_WITH_CHAT_3" | translate }}
    </p>
    <p *ngIf="THEME_CONFIGURATION.contactFormEnabled">
      <span class="me-2">❓</span
      >{{
        "ADD_NEW_ADDRESS"
          | translate : { brandName: THEME_CONFIGURATION.brandName }
      }}
      <a
        class="text-secondary"
        [routerLink]="[routePrefix, 'contact']"
        (click)="captureEvent('contact-us')"
        >{{ "CONTACT_US" | translate }}</a
      >
    </p>
  </div>
</div>
