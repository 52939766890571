/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import { Themes } from "@soliguide/common";
import type { WindowType } from "../types";

class ThemeService {
  theme: Themes;

  constructor() {
    if (
      "CURRENT_DATA" in window &&
      "THEME" in (window as WindowType).CURRENT_DATA
    ) {
      this.theme = (window as WindowType).CURRENT_DATA.THEME as Themes;
    } else {
      throw new Error("Theme not defined");
    }
  }

  public getTheme(): Themes {
    return this.theme;
  }

  public isSoliguideFr(): boolean {
    return this.theme === Themes.SOLIGUIDE_FR;
  }

  public isSoliguiaEs(): boolean {
    return this.theme === Themes.SOLIGUIA_ES;
  }

  public isSoliguiaAd(): boolean {
    return this.theme === Themes.SOLIGUIA_AD;
  }
}

// Not injectable service to use in constants file
export const themeService = new ThemeService();
