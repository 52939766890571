<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<section id="logos-partners" class="my-5" *ngIf="logosToDisplay.length">
  <h2 class="text-primary">{{ "WITH_THE_SUPPORT_OF" | translate }}</h2>
  <ng-container *ngFor="let logo of logosToDisplay">
    <img class="logo m-4" [src]="logo.path" [alt]="logo.alt" />
  </ng-container>
</section>
