<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="modal-header bg-primary">
  <span id="modal-title">{{ "COOKIES_TITLE" | translate }}</span>
  <button
    type="button"
    class="btn-close btn-close-white"
    [attr.aria-label]="'CLOSE' | translate"
    (click)="close()"
  ></button>
</div>
<div class="modal-body">
  <a (click)="close()" [routerLink]="[routePrefix, cookiePolicyLink]">{{
    "COOKIES_POLICY" | translate
  }}</a>
  <br />
  <table class="table">
    <caption class="visually-hidden">
      {{
        "COOKIES_TITLE" | translate
      }}
    </caption>
    <thead>
      <tr>
        <th scope="col">{{ "SERVICES" | translate }}</th>
        <th scope="col">{{ "LINK" | translate }}</th>
        <th scope="col">{{ "CONSENT" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Chatbot zendesk</td>
        <td>
          <a
            href="https://www.zendesk.fr/company/agreements-and-terms/privacy-notice/"
            target="_blank"
            >Zendesk</a
          >
        </td>
        <td>
          <div class="form-check form-switch m-0">
            <input
              class="form-check-input"
              type="checkbox"
              id="chatConsent"
              [(ngModel)]="chatConsent"
            />
            <label class="form-check-label" for="chatConsent"></label>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button type="button" (click)="setUserConsent()" class="btn btn-primary">
    {{ "SAVE_AND_CONTINUE" | translate }}
  </button>
</div>
