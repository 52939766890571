<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<ng-container *ngIf="needForm">
  <div class="container my-4" *ngIf="!success && signupForm">
    <div class="row">
      <div class="col-md-8 offset-md-2 my-2">
        <h1
          class="title"
          *ngIf="invitationToken"
          [innerHtml]="
            'CREATE_PROFESSIONAL_ACCOUNT'
              | translate : { brandName: THEME_CONFIGURATION.brandName }
          "
        ></h1>
        <h1
          class="title"
          *ngIf="!invitationToken"
          [innerHtml]="
            'CREATE_PERSONNAL_ACCOUNT'
              | translate : { brandName: THEME_CONFIGURATION.brandName }
          "
        ></h1>
        <div class="limite"></div>
      </div>

      <form
        [formGroup]="signupForm"
        class="col-md-8 offset-md-2"
        (ngSubmit)="signup()"
      >
        <div class="form-container">
          <div class="row" *ngIf="invitationToken">
            <div class="col-md-12 my-2">
              <label class="form-label" for="organizationName">{{
                "YOUR_ORGANIZATION" | translate
              }}</label>
              <input
                type="text"
                id="organizationName"
                formControlName="organizationName"
                class="form-control"
                [disabled]="true"
              />
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-6 my-2 required">
              <label for="name" class="form-label">
                {{ "NAME" | translate }}
              </label>
              <input
                id="name"
                type="text"
                class="form-control"
                formControlName="name"
                autocomplete="name"
                [placeholder]="'NAME' | translate"
                [ngClass]="{
                  'is-invalid': (f.name.dirty || submitted) && f.name.errors,
                  'is-valid': f.name.dirty && f.name.value && !f.name.errors
                }"
              />
              <p
                *ngIf="(f.name.dirty || submitted) && f.name.errors"
                class="invalid-feedback"
              >
                {{ "NAME_REQUIRED" | translate }}
              </p>
            </div>
            <div class="col-md-6 my-2 required">
              <label for="lastname" class="form-label">
                {{ "LASTNAME" | translate }}
              </label>
              <input
                id="lastname"
                type="text"
                class="form-control"
                formControlName="lastname"
                autocomplete="family-name"
                [placeholder]="'LASTNAME' | translate"
                [ngClass]="{
                  'is-invalid':
                    (f.lastname.dirty || submitted) && f.lastname.errors,
                  'is-valid':
                    f.lastname.dirty && f.lastname.value && !f.lastname.errors
                }"
              />
              <p
                *ngIf="(f.lastname.dirty || submitted) && f.lastname.errors"
                class="invalid-feedback"
              >
                {{ "LASTNAME_REQUIRED" | translate }}
              </p>
            </div>
          </div>

          <div class="col p-0 my-2 required">
            <label for="email" class="form-label">{{
              "EMAIL_ADDRESS" | translate
            }}</label>
            <input
              id="email"
              type="email"
              formControlName="mail"
              class="form-control"
              [disabled]="invitationToken !== null"
              autocomplete="email"
              [placeholder]="'EMAIL_ADDRESS' | translate"
              [ngClass]="{
                'is-invalid': (submitted || f.mail.value) && f.mail.errors,
                'is-valid': f.mail.value && !f.mail.errors
              }"
            />
            <div *ngIf="f.mail.errors" class="invalid-feedback">
              <p *ngIf="!f.mail.errors?.required">
                {{ "INCORRECT_EMAIL_ADDRESS" | translate }}
              </p>
              <p *ngIf="f.mail.errors?.required">
                {{ "EMAIL_REQUIRED" | translate }}
              </p>
              <p *ngIf="f.mail.errors.emailTaken">
                {{ "UNUSED_EMAIL_ADDRESS" | translate }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 my-2 col-12">
              <app-form-phone-input
                [phone]="signupForm.controls.phone.value"
                [parentForm]="signupForm"
                [submitted]="submitted"
              ></app-form-phone-input>
            </div>
            <div class="col-md-6 my-2">
              <label class="form-label" for="title">{{
                "TITLE" | translate
              }}</label>
              <input
                type="text"
                id="title"
                formControlName="title"
                autocomplete=""
                [placeholder]="'TITLE_PLACEHOLDER' | translate"
                class="form-control"
              />
            </div>
          </div>

          <div class="row">
            <ng-container *ngIf="!invitationToken">
              <div class="col-6 my-2">
                <div class="my-2 required">
                  <label class="form-label" for="status">{{
                    "USER_STATUS" | translate
                  }}</label>
                  <select
                    [ngClass]="{
                      'is-invalid': submitted && f.status.errors,
                      'is-valid':
                        f.status.dirty && f.status.value && !f.status.errors
                    }"
                    formControlName="status"
                    id="status"
                    required
                    class="form-select"
                  >
                    <option [ngValue]="null"></option>
                    <option [value]="'API_USER'">
                      {{ UserStatus.API_USER | translate }}
                    </option>
                    <option [value]="'ADMIN_TERRITORY'">
                      {{ UserStatus.ADMIN_TERRITORY | translate }}
                    </option>
                    <option [value]="'ADMIN_SOLIGUIDE'">
                      {{ UserStatus.ADMIN_SOLIGUIDE | translate }}
                    </option>
                  </select>
                  <p
                    *ngIf="(f.status.dirty || submitted) && f.status.errors"
                    class="invalid-feedback"
                  >
                    {{ "SELECT_STATUS" | translate }}
                  </p>
                </div>
              </div>
              <div class="col-6 my-2"></div>
              <div
                class="col-6 mt-2"
                *ngIf="
                  invitationToken ||
                  f.status.value === 'ADMIN_TERRITORY' ||
                  f.status.value === 'API_USER'
                "
              >
                <app-select-territories
                  [isRequired]="true"
                  [label]="
                    (invitationToken
                      ? 'WHICH_DEPARTMENT_BASED_IN'
                      : 'WHICH_DEPARTMENT_USER_OPERATE'
                    ) | translate
                  "
                  [submitted]="submitted"
                  [territoriesFormControl]="f.territories"
                ></app-select-territories>
              </div>
              <div class="col-6 my-2" *ngIf="f.status.value === 'API_USER'">
                <app-select-category
                  [submitted]="submitted"
                  [isRequired]="false"
                  (selectedCategories)="setCategories($event)"
                  [label]="'USER_SEARCHABLE_CATEGORIES' | translate"
                  [categories]="f.categoriesLimitations.value"
                ></app-select-category>

                <p
                  *ngIf="
                    (f.categoriesLimitations.dirty || submitted) &&
                    f.categoriesLimitations.errors
                  "
                  class="invalid-feedback-custom"
                >
                  {{ "SELECT_CATEGORY" | translate }}
                </p>
              </div>
            </ng-container>
          </div>

          <ng-container *ngIf="invitationToken">
            <app-user-password-form
              [parentFormGroup]="signupForm"
              [submitted]="submitted"
            ></app-user-password-form>

            <div class="row mt-2" *ngIf="invitationToken">
              <div class="cgu col-12 mt-4 my-2 required">
                <div
                  class="label-cbx d-flex"
                  [ngClass]="{
                    'is-invalid': (f.cgu.dirty || submitted) && f.cgu.errors
                  }"
                >
                  <input
                    id="cgu"
                    type="checkbox"
                    formControlName="cgu"
                    class="invisible"
                    [checked]="f.cgu.value"
                  />

                  <div class="checkbox">
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 20 20"
                      (click)="f.cgu.setValue(!f.cgu.value)"
                    >
                      <path
                        d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"
                      ></path>
                      <polyline points="4 11 8 15 16 6"></polyline>
                    </svg>
                  </div>

                  <div
                    [ngClass]="{
                      'text-danger': (f.cgu.dirty || submitted) && f.cgu.errors
                    }"
                    [innerHTML]="
                      'ACCEPT_GCU_WITH_LINK'
                        | translate : { routePrefix }
                        | safeHtml
                    "
                  ></div>
                </div>

                <div
                  *ngIf="
                    (f.cgu.dirty || submitted) &&
                    (f.cgu.errors || f.cgu.invalid)
                  "
                  class="invalid-feedback"
                >
                  <div *ngIf="f.cgu.errors?.required">
                    {{
                      "GCU_REQUIRED"
                        | translate
                          : { brandName: THEME_CONFIGURATION.brandName }
                    }}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="my-2 mt-4">
          <button
            type="submit"
            aria-label="Inscription"
            class="btn btn-lg btn-primary"
            [disabled]="loading"
          >
            <span *ngIf="!loading && !invitationToken">
              {{ "CREATE_YOUR_ACCOUNT" | translate }}
            </span>
            <span *ngIf="!loading && invitationToken">
              {{ "CREATE_YOUR_ACCOUNT" | translate }}
            </span>
            <span *ngIf="loading"
              ><fa-icon
                aria-hidden="true"
                [icon]="['fas', 'circle-notch']"
                [spin]="true"
              >
              </fa-icon>
              {{ "REGISTRATION_IN_PROGRESS" | translate }}</span
            >
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!needForm">
  <div class="container my-4">
    <div class="row">
      <div class="col-10 offset-1 my-2">
        <h1 class="title">
          {{
            "JOIN_NEW_ORGANIZATON"
              | translate : { brandName: THEME_CONFIGURATION.brandName }
          }}
        </h1>
        <div class="limite"></div>
        <div class="form-container">
          <div *ngIf="loading" class="fw-bold">
            <fa-icon
              aria-hidden="true"
              [icon]="['fas', 'circle-notch']"
              [spin]="true"
            >
            </fa-icon>
            {{ "VALIDATION_INVITATION_IN_PROGRESS" | translate }}
          </div>
          <ng-container class="fw-bold" *ngIf="!loading">
            <div *ngIf="!success">
              <fa-icon aria-hidden="true" [icon]="['fas', 'times']"> </fa-icon>
              {{ "UNABLE_VALIDATE_INVITATION" | translate }}
            </div>
            <div *ngIf="success">
              <fa-icon [icon]="['fas', 'check']" aria-hidden="true"> </fa-icon>
              {{ "YOUR_INVITATION_CONFIRMED" | translate }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
