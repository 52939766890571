<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div *ngIf="publics">
  <b *ngIf="!publics.accueil"
    ><fa-icon [icon]="['fas', 'users']" class="me-2"></fa-icon>
    {{ "PUBLICS_WELCOME_UNCONDITIONAL" | translate }}
  </b>

  <div class="my-1" *ngIf="publics.accueil">
    <b *ngIf="publics.accueil === WelcomedPublics.EXCLUSIVE"
      ><fa-icon [icon]="['fas', 'users']" class="me-2"></fa-icon>
      {{ "PUBLICS_WELCOME_EXCLUSIVE" | translate }}
    </b>
    <b *ngIf="publics.accueil === WelcomedPublics.PREFERENTIAL"
      ><fa-icon [icon]="['fas', 'users']" class="me-2"></fa-icon>
      {{ "PUBLICS_WELCOME_PREFERENTIAL" | translate }}
    </b>
    {{ publicsText }}
  </div>

  <div class="my-1" *ngIf="publics.description">
    <b>
      <fa-icon [icon]="['fas', 'info-circle']" class="me-2"></fa-icon>
      {{ "INFO_IMPORTANTE" | translate }}
    </b>
    <i>{{ publics.description }}</i>
  </div>
</div>
