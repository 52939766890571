<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<ul *ngFor="let point of place.parcours; let pointIndex = index">
  <li *ngIf="point.position.address" class="pt-1">
    <span class="left-icon-text">
      <fa-icon aria-hidden="true" [icon]="['fas', 'location-dot']"></fa-icon>
      {{ pointIndex + 1 }} -
      {{ point.position.address }}
    </span>
    <span *ngIf="point.position.additionalInformation" class="additional_info">
      ({{ point.position.additionalInformation }})
    </span>
  </li>

  <li *ngIf="point.description" class="point_description">
    <p class="m-0">{{ point.description }}</p>
  </li>

  <li *ngIf="point.photos.length > 0" class="point_photos">
    <app-display-photos
      [photos]="point.photos"
      [name]=""
      (parentCaptureEvent)="captureEvent($event)"
    ></app-display-photos>
  </li>

  <li>
    <span>{{ "APPROXIMATIVE_OPENING_HOURS" | translate }}</span>
    <app-display-horaires
      [hours]="point.hours"
      [displayClosedDays]="false"
    ></app-display-horaires>
  </li>
</ul>
