<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="page-content-header"></div>
<div class="container">
  <div class="page-content page-content-overlay">
    <div class="col-12 py-2">
      <h1 class="text-center separator-secondary">
        {{ "NEED_HELP" | translate }}
      </h1>
      <h2
        class="aide-title my-4"
        [innerHTML]="
          'AVAILABLE_TUTORIALS'
            | translate : { brandName: THEME_CONFIGURATION.brandName }
            | safeHtml
        "
      ></h2>
      <div class="limite"></div>
      <div class="row">
        <div class="col-12 col-md-3 mt-4">
          <a
            href="https://www.solinum.org/wp-content/uploads/2024/06/Tutoriel-utilisation-des-comptes-professionnels-Soliguide_juin2024.pptx.pdf"
            target="_blank"
            rel="noopener noreferrer"
            (click)="clickOnTutorial('[PDF] Comptes professionnels')"
          >
            <div class="card">
              <div class="card-body bg-light">
                <h4 class="card-title">
                  <span dir="auto">
                    [{{ "PDF" | translate }}]
                    {{ "BUSINESS_ACCOUNT" | translate }}
                  </span>
                </h4>
              </div>
            </div>
          </a>
        </div>
        <div class="col-12 col-md-3 mt-4">
          <a
            href="https://www.solinum.org/wp-content/uploads/2022/04/Parcours-mobile.pptx.pdf"
            target="_blank"
            rel="noopener noreferrer"
            (click)="clickOnTutorial('[PDF] Créer un parcours mobile')"
          >
            <div class="card">
              <div class="card-body bg-light">
                <h5 class="card-title">
                  <span dir="auto">
                    [{{ "PDF" | translate }}]
                    {{ "CREATE_MOBILE_ROUTE" | translate }}
                  </span>
                </h5>
              </div>
            </div>
          </a>
        </div>
        <div class="col-12 col-md-3 mt-4">
          <a
            href="https://www.solinum.org/wp-content/uploads/2022/02/Lannuaire-Soliguide.pdf"
            target="_blank"
            rel="noopener noreferrer"
            (click)="clickOnTutorial('[PDF] L\'annuaire professionnel')"
          >
            <div class="card">
              <div class="card-body bg-light">
                <h5 class="card-title">
                  <span dir="auto">
                    [{{ "PDF" | translate }}]
                    {{ "PROFESSIONAL_DIRECTORY" | translate }}
                  </span>
                </h5>
              </div>
            </div>
          </a>
        </div>
        <div class="col-12 col-md-3 mt-4">
          <a
            href="https://www.solinum.org/wp-content/uploads/2024/05/Tutoriel-mise-a-jour-saisonniere-Soliguide_juin2024.pptx.pdf"
            target="_blank"
            rel="noopener noreferrer"
            (click)="clickOnTutorial('[PDF] Mise à jour saisonnière')"
          >
            <div class="card">
              <div class="card-body bg-light">
                <h5 class="card-title">
                  <span dir="auto">
                    [{{ "PDF" | translate }}]
                    {{ "SEASONAL_UPDATE" | translate }}
                  </span>
                </h5>
              </div>
            </div>
          </a>
        </div>
      </div>
      <h3
        class="aide-title mb-4 mt-4"
        [innerHTML]="'WEBINAR_PARTICIPATION' | translate | safeHtml"
      ></h3>
      <div class="limite"></div>
      <p class="mt-4">
        {{ "MONTHLY_DEMONSTRATION" | translate }}
      </p>
      <a
        href="https://zoom.us/webinar/register/7216898420109/WN_gn7lx7IsTneAkRo5DW2qtQ"
        target="_blank"
        (click)="clickRegisterWebinar()"
        class="btn btn-primary mb-4 mt-2"
        >{{ "WEBINAR_SIGNUP" | translate }}</a
      >
      <h3
        class="aide-title mb-4 mt-4"
        [innerHTML]="'HAVE_A_QUESTION' | translate | safeHtml"
      ></h3>
      <div class="limite"></div>
      <p class="mt-4">
        <br />
        {{ "BY_EMAIL" | translate }}
        <a href="mailto:contact@solinum.org" (click)="clickEmailToContact()"
          >contact@solinum.org</a
        >

        <ng-container *ngIf="isChatEnabled">
          <br /><br />
          {{ "VIA_THE_CHAT" | translate }}

          <button
            type="button"
            class="btn btn-primary"
            (click)="openCookiesConsentModalOrChat()"
          >
            {{ "SEND_US_A_MESSAGE" | translate }}
          </button>
        </ng-container>
      </p>
    </div>
  </div>
</div>
<ng-template #cookiesConsentModal let-modal>
  <app-cookies-modal></app-cookies-modal>
</ng-template>
