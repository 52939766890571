<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div *ngIf="contact" class="row">
  <div class="col-12 col-md-5 align-self-center">
    <span>
      <span class="name fw-bold">
        {{ contact | userFullName }}
      </span>
      <span *ngIf="contact.title" class="title">
        {{ contact.title }}
      </span>
    </span>
  </div>
  <div class="col-12 col-md-3">
    <fa-icon
      *ngIf="contact.phone"
      aria-hidden="true"
      [icon]="['fas', 'phone']"
    ></fa-icon>
    <span *ngIf="contact.phone" class="fw-bold">
      <a
        href="tel:{{ contact.phone }}"
        (click)="
          captureEvent({
            name: 'click-contact-phone',
            properties: { phoneNumber: contact.phone }
          })
        "
        >{{ contact.phone | formatInternationalPhoneNumber }}</a
      >
    </span>
  </div>
  <div class="col-12 col-md-4">
    <fa-icon aria-hidden="true" [icon]="['fas', 'envelope']"></fa-icon>
    <span class="fw-bold">
      <a
        href="mailto:{{ contact.mail }}"
        (click)="
          captureEvent({
            name: 'click-contact-email',
            properties: { email: contact.mail }
          })
        "
        >{{ contact.mail }}</a
      >
    </span>
  </div>
</div>
