<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="container">
  <div class="step_form">
    <div class="row">
      <div class="col-12 col-md-8 offset-md-2">
        <ng-container *ngIf="!emailSent">
          <div class="text-center">
            <h1 class="separator-secondary">
              {{ "FORGOT_PASSWORD" | translate }}
            </h1>
            <p class="mt-1">
              {{ "CONNECTION_DESCRIPTION" | translate }}
            </p>
          </div>

          <form [formGroup]="forgotPwd" (ngSubmit)="forgotpwd()">
            <div class="my-2 required">
              <label for="email" class="form-label">{{
                "EMAIL_ADDRESS" | translate
              }}</label>
              <input
                id="email"
                type="email"
                formControlName="mail"
                class="form-control"
                autocomplete="email"
                [placeholder]="'EMAIL_ADDRESS' | translate"
                [ngClass]="{
                  'is-invalid': (submitted || f.mail.dirty) && f.mail.errors,
                  'is-valid': f.mail.dirty && f.mail.value && !f.mail.errors
                }"
              />
              <div
                *ngIf="(submitted || f.mail.dirty) && f.mail.errors"
                class="invalid-feedback"
              >
                <p *ngIf="f.mail.errors.invalidEmail">
                  {{ "INCORRECT_EMAIL_ADDRESS" | translate }}
                </p>
                <p *ngIf="f.mail.errors.required">
                  {{ "EMAIL_REQUIRED" | translate }}
                </p>
              </div>
            </div>
            <div class="form-group text-center">
              <button
                [disabled]="loading"
                type="submit"
                class="btn btn-primary"
              >
                <span *ngIf="loading">
                  <fa-icon
                    aria-hidden="true"
                    [icon]="['fas', 'circle-notch']"
                    [spin]="true"
                  >
                  </fa-icon>
                  {{ "PLEASE_WAIT" | translate }}
                </span>
                <span *ngIf="!loading">{{ "RESET_PASSWORD" | translate }}</span>
              </button>
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="emailSent">
          <div class="text-center">
            <p>
              {{
                "RESET_PASSWORD_EMAILING_MESSAGE"
                  | translate : { brandName: THEME_CONFIGURATION.brandName }
              }}
            </p>

            <button
              type="reset"
              class="btn btn-primary"
              (click)="emailSent = false"
            >
              {{ "BACK_TO_RESET_PASSWORD" | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
