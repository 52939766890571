<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div *ngIf="tempInfos.actif" class="alert alert-{{ tempInfos.infoColor }}">
  <div class="my-1 fw-bold">
    <fa-icon
      [icon]="[
        'fas',
        tempInfoType === TempInfoType.message
          ? 'info-circle'
          : 'exclamation-triangle'
      ]"
    ></fa-icon>

    <span *ngIf="tempInfoType === TempInfoType.message">
      {{ "INFO_TEMPORAIRE" | translate }}
    </span>
    <span *ngIf="tempInfoType === TempInfoType.closure && !tempInfos.isService">
      {{ "FERMETURE_TEMPORAIRE" | translate }}
    </span>
    <span *ngIf="tempInfoType === TempInfoType.closure && tempInfos.isService">
      {{
        (tempInfos.infoColor === "warning"
          ? "SERVICE_FERMERA_TEMP"
          : "SERVICE_FERME_TEMP"
        ) | translate
      }}
    </span>
    <span *ngIf="tempInfoType === TempInfoType.hours">
      {{ "TEMPORARY_HOURS" | translate }}
    </span>
    {{ dateString }}
  </div>

  <div *ngIf="tempInfos?.name || tempInfos?.description" class="my-1">
    <div *ngIf="tempInfos?.name" class="fw-bold">
      {{ tempInfos?.name }}
    </div>
    <div
      *ngIf="tempInfos?.description"
      class="m-0"
      [innerHTML]="tempInfos?.description"
    ></div>
  </div>

  <ng-container *ngIf="tempInfoType === TempInfoType.hours">
    <button
      type="submit"
      class="btn btn-outline-primary btn-block mt-2"
      *ngIf="displayTempHours"
      (click)="toogleDisplayTempHours(false)"
    >
      {{ "BTN_HORAIRES_HABITUELS" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-outline-primary btn-block mt-2"
      *ngIf="!displayTempHours"
      (click)="toogleDisplayTempHours(true)"
    >
      {{ "BTN_HORAIRES_TEMPORAIRES" | translate }}
    </button>
  </ng-container>

  <ng-container
    *ngIf="
      tempInfoType === TempInfoType.closure &&
      displayHoursWhenTempClosed &&
      !admin
    "
  >
    <button
      type="submit"
      class="btn btn-outline-primary btn-block mt-2"
      (click)="displayRegularHours()"
    >
      {{ "BTN_HORAIRES_HABITUELS" | translate }}
    </button>
  </ng-container>
</div>
