<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<!-- Banner temporarily deactivated until new app is done -->
<!-- <app-download-mobile-app></app-download-mobile-app> -->
<button
  type="button"
  class="btn btn-secondary"
  *ngIf="isChatEnabled && !IS_BOT && !hasUserGivenConsent"
  (click)="openChatCookiesConsentModal()"
  id="chat-placeholder"
>
  <img
    src="../assets/images/chat-icon.svg"
    alt="Logo messagerie Signpost"
    aria-hidden="true"
  />
  <span class="visually-hidden">
    {{ "COOKIES_TITLE" | translate }}
  </span>
</button>
<app-nav></app-nav>
<main id="page">
  <router-outlet></router-outlet>
</main>
<app-footer
  (openCookieConsentModal)="openFooterCookiesConsentModal()"
></app-footer>

<ng-template #cookiesConsentModal let-modal>
  <app-cookies-modal></app-cookies-modal>
</ng-template>
