<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="page-content-header"></div>
<div class="container">
  <div class="page-content page-content-overlay">
    <h1>Condicions generals d'ús de Soliguide</h1>
    <h2>1. Qui som?</h2>
    <p>
      La plataforma i el widget Soliguide són publicats per l'associació llei
      1901 Solinum el domicili social de la qual es troba al Carrer David
      Johnston, 153, 33000 Bordeus.
    </p>
    <p>
      Pots contactar amb nosaltres en qualsevol moment a
      <a href="mailto:contact@solinum.org">contact@solinum.org</a>.
    </p>
    <p>
      Soliguide està allotjat per Digital Ocean situat al número 101 de la 6a
      avinguda, Nova York 10013, els servidors del qual es troben a la Unió
      Europea, Scaleway, situada al número 8, Carrer de la ville l’Evêque, 75008
      París i Clever Cloud situada al número 3, Carrer de L'Allier, 44000
      Nantes.
    </p>

    <h2>2. Quin és l'ús de les condicions generals?</h2>
    <p>
      La confiança que tens en nosaltres cada dia és la nostra prioritat. Així
      doncs, fem tot el possible per garantir que puguis mantenir aquesta
      confiança diàriament, cosa que, òbviament, requereix condicions generals
      d'ús (<b>"CGU"</b>) respectades i conegudes per tothom.
    </p>
    <p>
      Les CGU, que s'apliquen a tots els serveis prestats per Soliguide, tenen
      precisament l'objectiu de presentar-te les normes que has de respectar
      quan l'utilitzis, sabent que qualsevol ús de Soliguide implica l'adhesió
      sense reserves a les CGU.
    </p>
    <p>
      Per tant, cal certificar que has llegit les CGU abans d'utilitzar
      Soliguide i que disposes de tota la informació i les habilitats
      necessàries per garantir que els serveis prestats corresponen a les teves
      necessitats.
    </p>
    <h2>3. Quines són les condicions per tenir un compte?</h2>
    <p>
      Per crear un compte a Soliguide, cal ser una persona física, major d'edat,
      amb plena capacitat legal per comprometre't amb les CGU.
    </p>
    <p>
      El teu compte és estrictament personal. Per tant, et compromets a
      facilitar les dades identificatives necessàries per a la creació del
      compte de manera personal, correcta, exacta i veraç. Així mateix, hauràs
      de comunicar-nos qualsevol modificació relativa a la informació que cal
      facilitar per a l'ús normal de Soliguide.
    </p>
    <p>
      Ets responsable de mantenir la confidencialitat del teu compte i
      contrasenya, així com de les restriccions d'accés al teu terminal (per
      exemple, tauleta, mòbil, etc.). En cas de dubte sobre la vulnerabilitat
      del teu compte o la confidencialitat del teu accés, haurà de canviar la
      teva contrasenya i/o contactar amb el nostre servei d'assistència al més
      aviat possible. En cas contrari, i en la mesura en què ho permeti la
      legislació aplicable, ets responsable de totes les activitats que s'hagin
      dut a terme des del teu compte.
    </p>
    <p>
      Et recomanem que utilitzis una contrasenya complexa (formada per números,
      lletres minúscules i majúscules i signes de puntuació) per augmentar la
      seguretat d'accés al compte i canviar les contrasenyes regularment
      accedint a la configuració del compte.
    </p>
    <h2>4. El widget Soliguide és gratuït?</h2>
    <p>
      Actualment, el widget Soliguide es proporciona de forma gratuïta.
      Tanmateix, ens reservem el dret de cobrar per l’ús del widget o d’algunes
      funcions. En aquest cas, òbviament, se’t notificarà abans de qualsevol
      modificació de les regles d’ús del widget Soliguide.
    </p>
    <h2>5. Quins són els nostres compromisos?</h2>
    <p>
      Fem tot el possible per oferir-te un servei de qualitat, accessible
      24/24h, 7/7d, oferint exactes i precisos, sense cap risc de fallada
      tècnica.
    </p>
    <p>
      No obstant això, atès que el "risc zero" no existeix en matèria
      informàtica, la nostra responsabilitat no es pot comprometre en cas de
      danys que es puguin produir a causa de l'ús de Soliguide perquè no podem
      ser responsables dels possibles errors, indisponibilitat o interrupció que
      es puguin produir a Soliguide o en el cas que un virus sigui accessible
      des de Soliguide. Tampoc som responsables dels retards o de la teva
      incapacitat per complir amb les teves obligacions en virtut de les CGU, si
      el retard o impossibilitat resulta d'una causa fora del nostre control
      lògic.
    </p>
    <p>
      Tampoc ens fem responsables dels pirates informàtics, errors o virus que
      puguin instal·lar-se en el teu terminal i que afectin el servei, o dels
      passos o accions realitzats o omesos per un administrador del sistema.
    </p>
    <p>
      A més, el teu accés a Soliguide o als serveis pot ser ocasionalment suspès
      o limitat per permetre reparacions, manteniment o afegir una nova funció o
      un nou servei.
    </p>
    <p>
      En tot cas, s'acorda expressament que només estem subjectes a una
      obligació de mitjans per al subministrament de Soliguide. A més, acceptes
      que la nostra responsabilitat només es pot incórrer en cas de culpa
      nostra, sabent que aquesta responsabilitat només pot ser aplicable per
      danys directes i previsibles, quedant exclosos els danys indirectes i que
      no som responsables de pèrdues comercials (incloses pèrdues de beneficis,
      guanys, contractes, estalvis previstos, dades o despeses innecessàries),
      que no eren previsibles quan vas començar a utilitzar Soliguide.
    </p>
    <p>
      Es consideren danys indirectes, en particular, la pèrdua de dades, temps,
      facturació, marges, pèrdua de comandes, membres, explotació, ingressos, o
      fins i tot danys a la imatge de marca, els resultats esperats o l'acció de
      tercers i, en general, qualsevol problema relacionat amb la teva
      activitat. De la mateixa manera, qualsevol acció d'un tercer en contra teu
      es considera indirecta.
    </p>
    <p>
      A més, s'informa que qualsevol cas de força major suspèn immediatament i
      automàticament l'execució de Soliguide. Si els casos de força major tenen
      una durada d'existència superior a dos mesos, ens reservem el dret de
      suspendre o donar per acabat el servei sense previ avís ni indemnització.
    </p>
    <p>
      En particular, es consideren casos de força major aquells fets aliens al
      nostre control, que no es van poder preveure raonablement quan es va posar
      a disposició Soliguide, i els efectes dels quals no s'han pogut evitar
      mitjançant les mesures adequades que impedeixen la seva utilització.
    </p>
    <p>
      Concretament, es tracta dels que a jurisprudència dels jutjats i tribunals
      francesos sol reconèixer com la guerra, els disturbis, els incendis, les
      vagues internes o externes, els tancaments patronals, l'ocupació de
      locals, el mal temps, els terratrèmols, les inundacions, els danys per
      aigua, les restriccions legals o governamentals, els canvis legals o
      reglamentaris, els accidents de tota mena, epidèmies, pandèmies, malalties
      que afecten més del 10% del personal de la nostra associació durant un
      període de dos mesos consecutius, l'absència de subministrament d'energia,
      parcial o total apagat de la xarxa d'internet i, de manera més general, de
      les xarxes de comunicacions privades o públiques de telecomunicacions, els
      bloquejos viaris i la impossibilitat de subministrament de material, i
      així mateix, qualsevol altre cas aliè al nostre control que impedeixi la
      normal execució de Soliguide.
    </p>
    <h2>6. Quines són les teves obligacions i els teus deures?</h2>
    <p>
      Soliguide i els seus serveis estan destinats únicament a finalitats
      informatives i en cap cas se'n pot fer un ús comercial. Et compromets a no
      vendre, llogar o comercialitzar els elements Soliguide.
    </p>
    <p>
      A més, et compromets a no organitzar, modificar, descompilar, desmuntar,
      fer enginyeria inversa, traduir, adaptar, reproduir, distribuir, difondre
      o transferir qualsevol informació, qualsevol programari, qualsevol
      producte i, en general, qualsevol element de Soliguide. Es prohibeix
      expressament la publicació, el desballestament, l'ús automatitzat o l'ús
      de gran volum de Soliguide.
    </p>
    <p>
      Quan utilitzis Soliguide, et compromets a respectar en tot moment (i) les
      lleis i les reglamentacions nacionals i internacionals vigents i així
      mateix (ii) les CGU. 
    </p>
    <p>
      <strong
        >ENS ASSEGURES QUE NO UTILITZARÀS SOLIGUIDE AMB FINALITATS IL·LEGALS,
        IMMORALS O CONTRÀRIES A L'ÚS PREVIST I/O AL SEU PROPÒSIT.</strong
      >
    </p>
    <p>
      En utilitzar Soliguide, reconeixes que només tens un dret d'ús per al teu
      compte. En qualsevol cas, et compromets, directament o indirectament, a
      no:
    </p>
    <ul>
      <li>
        utilitzar Soliguide de manera que atempti contra els drets de propietat
        intel·lectual o industrial, el dret a la intimitat i/o el dret a la
        imatge i/o qualsevol altre dret de tercers.
      </li>
      <li>
        crear, utilitzar, compartir i/o publicar per qualsevol mitjà (fòrum,
        perfil públic o un altre) qualsevol material (text, comentaris, imatges,
        sons, vídeos, etc.) o continguts que fossin, al nostre parer, agressius,
        amenaçadors, maliciosos, difamatoris, falsos, pornogràfics, pedòfils,
        obscens, vulgars, racistes, xenòfobs, incitants a l'odi, sexualment
        explícits, violents, contraris a la moral o censurables de qualsevol
        altra manera.
      </li>
      <li>
        crear, utilitzar, compartir i/o publicar per qualsevol mitjà (fòrum,
        perfil públic o un altre) qualsevol material (text, comentaris, imatges,
        sons, vídeos, etc.) que constituiria una violació d'una obligació de
        confidencialitat o que incitaria a cometre una activitat il·lícita (en
        particular, pirateria, craqueig o distribució de programari falsificat).
      </li>
      <li>
        realitzar activitats encaminades a accedir a elements o funcionalitats
        dels serveis l'ús dels quals es realitzi sense la nostra autorització
        prèvia i expressa.
      </li>
      <li>
        organitzar, modificar, traduir, adaptar, reproduir, indexar, copiar i/o
        extreure qualsevol informació, qualsevol programari, qualsevol producte,
        qualsevol base de dades o qualsevol altre element o part de Soliguide,
        per qualsevol mitjà sense la nostra autorització prèvia i expressa.
      </li>
      <li>
        modificar, distorsionar, bloquejar, carregar anormalment, interrompre,
        alentir, i/o impedir el normal funcionament de tot o d'una part de
        Soliguide, la seva accessibilitat a altres usuaris o el funcionament de
        les xarxes associades, o intentar fer tot l'anterior.
      </li>
      <li>
        transmetre o propagar qualsevol virus, cavall de Troia, cuc, bomba,
        fitxer alterat i/o dispositiu de destrucció similar o dades danyades,
        i/o organitzar, participar o estar implicat/da de qualsevol manera en un
        atac contra els nostres servidors i/o Soliguide, i o els servidors i/o
        serveis dels nostres proveïdors de serveis i socis.
      </li>
      <li>
        assetjar altres usuaris de Soliguide, enviar-los missatges no
        sol·licitats amb finalitats de prospecció comercial, utilitzar els
        Serveis amb finalitats d'enquestes, concursos, vendes piramidals o
        operacions similars, o enviar correus electrònics, correu brossa o
        qualsevol element publicitari o promocional no sol·licitat, amb
        finalitats comercials o no comercials.
      </li>
      <li>
        utilitzar el compte d'un altre usuari de Soliguide, fer-se passar per
        una altra persona o atribuir-li una falsa qualitat en les relacions amb
        qualsevol persona física o jurídica.
      </li>
      <li>
        recollir o interceptar per qualsevol mitjà que no estigui expressament
        permès dades per part d'altres usuaris, o els noms/pseudònims i/o
        contrasenyes de qualsevol altre usuari.
      </li>
      <li>
        intentar obtenir una contrasenya, informació del compte o altra
        informació privada de qualsevol altre usuari de Soliguide, i/o vendre,
        llogar, compartir, prestar i/o de qualsevol altra manera transferir el
        propi compte i/o els mitjans d'accés al mateix a un tercer i/o de
        qualsevol altra manera fer que un tercer se'n beneficiï.
      </li>
      <li>fer un ús indegut del servei de suport que oferim.</li>
      <li>
        accedir, utilitzar, descarregar a través de Soliguide, reproduir o
        proporcionar a qualsevol persona (de forma gratuïta o pagant) qualsevol
        directori d'usuaris de Soliguide o informació relacionada amb els
        usuaris o l'ús de Soliguide.
      </li>
    </ul>
    <p>
      Si observes un comportament maliciós o contrari a les nostres CGU i als
      interessos d'altres usuaris, pots contactar amb nosaltres a<a
        href="mailto:contact@solinum.org"
        >contact@solinum.org</a
      >.
    </p>
    <h2>7. Es pot suspendre o desactivar el teu compte?</h2>
    <p>
      Podem limitar, restringir, suspendre o interrompre permanentment tot o
      part del teu compte, en qualsevol moment, sense previ avís i sense
      formalitat prèvia, en cas d'incompliment dels teus deures i obligacions en
      virtut de les CGU o a petició expressa d'una autoritat administrativa o
      judicial, o en el cas d'una infracció real o suposada de qualsevol dret,
      tot això sense que aquesta mesura es consideri com un incompliment de les
      nostres obligacions.
    </p>
    <p>
      Qualsevol tancament del teu compte d'administrador comporta la supressió
      definitiva de les dades personals que ens hagis comunicat en obrir o
      actualitzar el teu compte. D'altra banda, totes les dades relatives a la
      teva estructura, que no siguin dades personals, es conservaran com a part
      de la constitució de la nostra base de dades.
    </p>
    <h2>
      8. Quines són les normes aplicables en matèria de propietat intel·lectual?
    </h2>
    <p>
      Som i seguim sent els propietaris exclusius de tots els drets de propietat
      intel·lectual sobre les marques (per exemple: Soliguide, Solinum, etc.),
      carta gràfica o logotips, o qualsevol altre element que puguis visualitzar
      quan utilitzis Soliguide.
    </p>
    <p>
      Som i seguim sent propietaris exclusius de tots els drets de propietat
      intel·lectual i llicències de tercers editors aplicables a l'estructura
      general de Soliguide, com ara el programari utilitzat pel seu
      funcionament, els algoritmes que hem desenvolupat, les API que utilitzem,
      el contingut de Soliguide que escrivim i/o escollim (per exemple: text,
      so, dibuix, Política de Privadesa, Política de galetes, etc.), la nostra
      experiència, etc.
    </p>
    <p>
      El fet que puguis accedir-hi lliurement mentre l'utilitzis, no implica que
      et concedim cap llicència o dret d'ús dels elements Soliguide.
    </p>
    <p>
      Per tant, en cap cas no tens dret a utilitzar aquests elements, totalment
      o parcialment, sense la nostra autorització prèvia i per escrit. Qualsevol
      ús per part teva que no hàgim autoritzat pot constituir una infracció
      castigada pels articles L335-2i següents del Codi de la propietat
      intel·lectual i pels articles L713-2i següents del mateix codi.
    </p>
    <p>
      Soliguide també podrà esmentar, per diversos motius, altres logotips,
      marques registrades que ens pertanyen o de tercers i protegides per la
      llei de propietat industrial. Per tant, qualsevol ús no autoritzat
      expressament d'aquests elements constitueix una infracció.
    </p>
    <p>
      De conformitat amb el que estableix la Llei 98-536 d'1 de juliol del 1998
      per la qual s'incorpora al Codi de la propietat intel·lectual la Directiva
      96/9 CE d'11 de març del 1996 relativa a la protecció jurídica de les
      bases de dades, t'informem que som els productors i titulars de la
      totalitat o part de les bases de dades utilitzades a través de Soliguide.
      Per tant, queda prohibida qualsevol extracció i/o reutilització de les
      bases de dades d'acord amb els articles L342-1 i L342-2 del Codi de la
      propietat intel·lectual.
    </p>
    <h2>9. Com utilitzem les teves dades personals?</h2>
    <p>
      Quan treballem junts i utilitzes Soliguide, no tenim més remei que
      processar les teves dades personals, concretament per processar les teves
      sol·licituds, gestionar les nostres relacions d'associació, etc.
    </p>
    <p>
      Per facilitar-te la comprensió de com tractem les teves dades, hem
      redactat una Política de Privadesa, accessible en qualsevol moment a
      Soliguide, que té com a objectiu presentar-te els motius i la manera en
      què tractem les teves dades en el marc dels serveis que prestem.
    </p>
    <p>
      En alguns casos, si inclous dades personals (per exemple: número de
      telèfon mòbil o cognom, nom d'una persona), ens convertim en
      subcontractistes d'acord amb el RGPD. En aquest cas, les teves dades estan
      protegides per l'acord de protecció de dades que pots consultar
      <a
        [title]="linkTitle"
        [routerLink]="[routePrefix, dataProcessingAgreementLink]"
        >aquí</a
      >.
    </p>
    <h2>10. Quin és el llenguatge "estàndard" aplicable?</h2>
    <p>
      Soliguide es pot traduir a diferents idiomes. No obstant això, el francès
      continua sent la llengua "estàndard" de la nostra associació i de la
      nostra activitat. Per tant, el francès sempre preval sobre les altres
      versions de Soliguide en el marc de la nostra relació laboral, en
      particular en cas de mala interpretació, protesta, litigi o dificultat per
      interpretar els nostres serveis.
    </p>
    <h2>11. Quines són les regles relatives als enllaços d'hipertext?</h2>
    <p>
      Soliguide pot contenir enllaços d'hipertext envers pàgines web d'internet
      o pàgines web de tercers. T'informem que no som responsables ni estem
      compromesos respecte de les ofertes, actes, continguts, productes, serveis
      o qualsevol altre element disponible a través d'aquests enllaços.
    </p>
    <p>
      A més, t'agrairíem per endavant que ens notifiquessis qualsevol enllaç
      d'hipertext present a Soliguide que permetés l'accés a una tercera pàgina
      web oferint continguts contraris a les lleis o la moral.
    </p>
    <h2>12. Acord sobre comprovants</h2>
    <p>
      Arxivem les comunicacions, arxius, contractes, comandes de compra i
      factures en un suport fiable i sostenible per tenir-ne una còpia conforme
      i sostenible. Aquestes comunicacions, fitxers, contractes, ordres de
      compra i factures es poden presentar com a comprovant. Llevat que es
      demostri el contrari, les dades registrades pels nostres equips
      constitueixen una prova de tots els intercanvis entre tu i nosaltres.
    </p>
    <h2>13. Nul·litat</h2>
    <p>
      La nul·litat d'una de les presents clàusules no comporta la nul·litat de
      les CGU i les altres clàusules continuaran produint els seus efectes.
    </p>
    <h2>14. Renúncia</h2>
    <p>
      El fet, per a Solinum, de no acollir-se de manera temporal o permanent a
      una o diverses clàusules de les CGU, en cap cas implica la renúncia al seu
      dret a fer-ho.
    </p>
    <h2>15. Quina és la legislació aplicable??</h2>
    <p>
      Solinum és una associació regida per la legislació francesa. En
      conseqüència, l'ús de Soliguide està subjecte a la legislació francesa i
      qualsevol controvèrsia serà interposada exclusivament davant els jutjats
      francesos competents.
    </p>
    <h2>16. Es poden modificar les condicions generals?</h2>
    <p>
      Ens reservem el dret de modificar les CGU en qualsevol moment i sense
      previ avís. Per tant, t'aconsellem que les consultis al més regularment
      possible.
    </p>
  </div>
</div>
