/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebook,
  faFacebookF,
  faFacebookMessenger,
  faInstagram,
  faLinkedinIn,
  faTelegram,
  faTelegramPlane,
  faTiktok,
  faTwitter,
  faWhatsapp,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faClock, faStar } from "@fortawesome/free-regular-svg-icons";
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faBan,
  faBars,
  faBowlFood,
  faBuilding,
  faCalendar,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faCode,
  faComment,
  faCopy,
  faCutlery,
  faDoorOpen,
  faDownload,
  faEdit,
  faEnvelope,
  faEnvelopeOpenText,
  faEuroSign,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileMedical,
  faFilter,
  faFloppyDisk,
  faHashtag,
  faHome,
  faInfoCircle,
  faKitchenSet,
  faLanguage,
  faLifeRing,
  faLink,
  faList,
  faLocationDot,
  faLock,
  faMap,
  faMapMarker,
  faPaperclip,
  faPaperPlane,
  faPauseCircle,
  faPaw,
  faPen,
  faPencilAlt,
  faPhone,
  faPlus,
  faPrint,
  faQuestion,
  faQuestionCircle,
  faRedo,
  faRss,
  faSearch,
  faShare,
  faShoppingCart,
  faSignOutAlt,
  faSms,
  faSnowflake,
  faSort,
  faSortAmountDown,
  faSortAmountUp,
  faSpinner,
  faSuitcase,
  faSun,
  faSync,
  faTicketAlt,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash,
  faUpload,
  faUser,
  faUserPlus,
  faUsers,
  faUserSecret,
  faUserShield,
  faWheelchair,
} from "@fortawesome/free-solid-svg-icons";

export const FA_ICONS: IconDefinition[] = [
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faBan,
  faBars,
  faBowlFood,
  faBuilding,
  faCalendar,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faClock,
  faCode,
  faComment,
  faCopy,
  faCutlery,
  faDoorOpen,
  faDownload,
  faEdit,
  faEnvelope,
  faEnvelopeOpenText,
  faEuroSign,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFacebook,
  faFacebookMessenger,
  faFileAlt,
  faFileMedical,
  faFilter,
  faFloppyDisk,
  faHashtag,
  faFacebookF,
  faHome,
  faInfoCircle,
  faInstagram,
  faKitchenSet,
  faLanguage,
  faLifeRing,
  faLink,
  faLinkedinIn,
  faList,
  faLocationDot,
  faLock,
  faMap,
  faMapMarker,
  faPaperclip,
  faPaperPlane,
  faPauseCircle,
  faPaw,
  faPen,
  faPencilAlt,
  faPhone,
  faPlus,
  faPrint,
  faQuestion,
  faQuestionCircle,
  faRedo,
  faRss,
  faSearch,
  faShare,
  faShoppingCart,
  faSignOutAlt,
  faSms,
  faSnowflake,
  faSort,
  faSortAmountDown,
  faSortAmountUp,
  faSpinner,
  faStar,
  faSuitcase,
  faSun,
  faSync,
  faTelegram,
  faTelegramPlane,
  faTiktok,
  faTicketAlt,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash,
  faTwitter,
  faUpload,
  faUser,
  faUserPlus,
  faUsers,
  faUserSecret,
  faUserShield,
  faWhatsapp,
  faWheelchair,
  faXTwitter,
  faYoutube,
];
