<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="page-content-header"></div>
<div class="container">
  <div class="page-content page-content-overlay">
    <h1>Avisos legals</h1>
    <p>Associació Solínum</p>
    <p>
      Llei d'associació 1901 la seu central es troba al 16 Place des Quinconces
      33000 Bordeaux.
    </p>
    <p>
      Correu electrònic:
      <a href="mailto:contact@solinum.org">contact@solinum.org</a>
    </p>
    <p>Responsable de la publicació: Pierre Joffre</p>
    <p>
      Aquest lloc web està allotjat per Clever Cloud, situat al 3 rue de
      l’Allier 44000 Nantes, OVH situat 2 rue Kellermann, 59100 Roubaix i
      Scaleway situat al 8 rue de la Ville l’Evêque, 75008 Paris, els servidors
      del qual són situat dins la Unió Europea.
    </p>
  </div>
</div>
