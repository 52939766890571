<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div [class]="'CSS_CLASS_FOR_LANGUAGE_SIZE' | translate">
  <ul class="nav_categories" *ngIf="!search.category">
    <li
      *ngFor="let categoryWithChildren of CATEGORIES_ROOTS"
      [ngClass]="{ active: search.category === categoryWithChildren }"
    >
      <a
        [routerLink]="[
          routePrefix,
          'search',
          search.location.geoValue,
          categoryWithChildren
        ]"
        [queryParams]="searchParams"
        (click)="
          captureEvent('click-category' + categoryWithChildren.toUpperCase())
        "
      >
        {{ categoryWithChildren | uppercase | translate }}
      </a>
    </li>
  </ul>

  <ul
    class="subCats nav_categories"
    *ngIf="search.category && categoryParentNode"
  >
    <li>
      <a
        [attr.aria-label]="'BACK_TO_PREVIOUS_CATEGORY' | translate"
        [routerLink]="[routePrefix, 'search', search.location.geoValue]"
        [queryParams]="searchParams"
        (click)="captureEvent('click-back-to-previous-category')"
      >
        <fa-icon aria-hidden="true" [icon]="['fas', 'arrow-left']"></fa-icon>
      </a>
    </li>
    <li class="active">
      <a
        [routerLink]="[
          routePrefix,
          'search',
          search.location.geoValue,
          categoryParentNode.id
        ]"
        [queryParams]="searchParams"
        (click)="captureEvent('click-category' + categoryParentNode.id)"
      >
        {{ categoryParentNode.id | uppercase | translate }}
      </a>
    </li>

    <ng-container *ngFor="let categoryId of categoryParentNode.children">
      <li [ngClass]="{ active: search.category === categoryId.id }">
        <a
          [routerLink]="[
            routePrefix,
            'search',
            search.location.geoValue,
            categoryId.id
          ]"
          [queryParams]="searchParams"
          (click)="captureEvent('click-service-' + categoryId.id)"
        >
          {{ categoryId.id | uppercase | translate }}
        </a>
      </li>
    </ng-container>
  </ul>
</div>
