<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<ng-container
  *ngIf="template === 'admin' || template === 'historique'; else placeComponent"
>
  <div class="my-3">
    <div *ngIf="!nContacts" class="row px-2">
      <div class="col-12 alert-info alert">
        {{ "NO_CONTACT_STRUCTURE" | translate }}
      </div>
    </div>
    <ng-container *ngFor="let contact of contacts; let i = index">
      <app-single-contact
        [contact]="contact"
        [index]="i"
        (parentCaptureEvent)="captureEvent($event)"
      ></app-single-contact>
      <hr *ngIf="i < nContacts - 1" />
    </ng-container>
  </div>
</ng-container>

<ng-template #placeComponent>
  <ng-container *ngIf="ableToSeeContacts && nContacts">
    <div class="my-3">
      <div class="page-content my-4">
        <div class="row">
          <div class="col-md-11">
            <h2
              class="text-center text-uppercase title-section separator-secondary"
            >
              {{ "CONTACTS" | translate }}
            </h2>
          </div>
          <div *ngIf="toCollapse" class="col-md-1 align-top text-end">
            <button
              type="button"
              class="btn"
              (click)="loadMoreContacts()"
              [attr.aria-label]="
                (collapsed ? 'SHOW_MORE' : 'SHOW_LESS') | translate
              "
            >
              <fa-icon
                [icon]="collapsed ? ['fas', 'angle-down'] : ['fas', 'angle-up']"
              ></fa-icon>
            </button>
          </div>
        </div>
        <ng-container *ngFor="let contact of contacts; let i = index">
          <ng-container *ngIf="i < nLimit">
            <app-single-contact
              [contact]="contact"
              [index]="i"
              (parentCaptureEvent)="captureEvent($event)"
            ></app-single-contact>
            <hr *ngIf="i < nLimit - 1" />
          </ng-container>
        </ng-container>
        <div *ngIf="toCollapse" class="d-flex justify-content-center mt-2">
          <button
            class="btn btn-outline-primary"
            type="button"
            (click)="loadMoreContacts()"
            [attr.aria-label]="
              (collapsed ? 'SHOW_MORE' : 'SHOW_LESS') | translate
            "
          >
            <span>
              {{ (collapsed ? "SHOW_MORE" : "SHOW_LESS") | translate }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
