<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->

<div class="container py-3">
  <div class="row align-items-center" *ngIf="user">
    <div class="col-md-5 offset-md-2 my-4">
      <h1 class="title text-primary">
        <span
          [innerHtml]="
            'SOLIGUIDE_PROFILE'
              | translate : { brandName: THEME_CONFIGURATION.brandName }
          "
        ></span
        ><br />
        <span [innerHtml]="'CHANGE_YOUR_DETAILS' | translate"></span>
      </h1>
      <div class="limite"></div>
    </div>
    <div class="col-md-3 text-end">
      <a
        [attr.aria-label]="'CHANGE_PASSWORD' | translate"
        [title]="'CHANGE_PASSWORD' | translate"
        class="btn btn-outline-primary"
        [routerLink]="[routePrefix, 'forgot-password']"
      >
        <fa-icon aria-hidden="true" [icon]="['fas', 'lock']"></fa-icon>
        {{ "CHANGE_PASSWORD" | translate }}
      </a>
    </div>
    <div class="col-md-8 offset-md-2 mb-5">
      <div class="form-container">
        <form [formGroup]="updateForm">
          <div class="row mt-3">
            <div class="col-md-6 my-2 required">
              <label for="name" class="form-label">
                {{ namePlaceholder | translate }}
              </label>
              <input
                id="name"
                type="text"
                class="form-control"
                formControlName="name"
                autocomplete="name"
                [placeholder]="namePlaceholder | translate"
                [ngClass]="{
                  'is-invalid': (f.name.dirty || submitted) && f.name.errors,
                  'is-valid': f.name.dirty && f.name.value && !f.name.errors
                }"
              />
              <div
                *ngIf="(f.name.dirty || submitted) && f.name.errors"
                class="invalid-feedback"
              >
                {{ namePlaceholder + "_REQUIRED" | translate }}
              </div>
            </div>
            <div
              *ngIf="!user.translator || user.status !== 'SIMPLE_USER'"
              class="col-md-6 my-2 required"
            >
              <label for="lastname" class="form-label">
                {{ "LASTNAME" | translate }}
              </label>
              <input
                id="lastname"
                type="text"
                class="form-control"
                formControlName="lastname"
                autocomplete="family-name"
                [placeholder]="'LASTNAME' | translate"
                [ngClass]="{
                  'is-invalid':
                    (f.lastname.dirty || submitted) && f.lastname.errors,
                  'is-valid':
                    f.lastname.dirty && f.lastname.value && !f.lastname.errors
                }"
              />
              <div
                *ngIf="(f.lastname.dirty || submitted) && f.lastname.errors"
                class="invalid-feedback"
              >
                {{ "LASTNAME_REQUIRED" | translate }}
              </div>
            </div>
          </div>

          <div class="col p-0 my-2 required">
            <label for="email" class="form-label">{{
              "EMAIL_ADDRESS" | translate
            }}</label>
            <input
              id="email"
              type="email"
              formControlName="mail"
              class="form-control"
            />
          </div>

          <div
            *ngIf="!user.translator || user.status !== 'SIMPLE_USER'"
            class="row"
          >
            <div class="col-md-6 my-2 col-12">
              <app-form-phone-input
                [phone]="user.phone"
                [parentForm]="updateForm"
                [submitted]="submitted"
              ></app-form-phone-input>
            </div>
            <div class="col-md-6 my-2">
              <label class="form-label" for="title">{{
                "TITLE" | translate
              }}</label>
              <input
                type="text"
                id="title"
                formControlName="title"
                autocomplete=""
                [placeholder]="'TITLE_PLACEHOLDER' | translate"
                class="form-control"
              />
            </div>
          </div>

          <div *ngIf="me?.status === 'ADMIN_SOLIGUIDE'" class="row">
            <div class="col-6">
              <app-select-territories
                [isRequired]="true"
                [label]="'TERRITORY_I_WORK_ON' | translate"
                [submitted]="submitted"
                [territoriesFormControl]="f.territories"
              ></app-select-territories>
            </div>
          </div>

          <br />

          <div class="row">
            <div class="col-6" *ngIf="me?.status">
              <div class="form-switch">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="translator"
                  formControlName="translator"
                />
                <label class="form-check-label" for="translator">
                  <span>{{
                    "WOULD_TRANSLATE_SOLIGUIDE"
                      | translate : { brandName: THEME_CONFIGURATION.brandName }
                  }}</span>
                </label>
              </div>
            </div>

            <div
              *ngIf="f.translator.value === true"
              class="col-md-6 my-2 required"
            >
              <span class="form-label">{{ "LISTE_LANGUES" | translate }}</span>
              <div
                class="custom-dropdown"
                [ngClass]="{
                  'is-invalid-languages is-invalid':
                    (f.languages.touched || submitted) && f.languages.errors,
                  'is-valid-languages is-invalid':
                    f.languages.touched &&
                    f.languages.value &&
                    !f.languages.errors
                }"
                placement="auto"
                ngbDropdown
                autoClose="outside"
              >
                <button type="button" id="listLanguages" ngbDropdownToggle>
                  <span class="text-truncate"> {{ getStringToDisplay() }}</span>
                </button>
                <div ngbDropdownMenu>
                  <ng-container
                    *ngFor="let language of SUPPORTED_LANGUAGES; let i = index"
                  >
                    <button
                      type="button"
                      (click)="toggleCheckboxButton(language)"
                      ngbDropdownItem
                    >
                      <input
                        id="lang-{{ language }}"
                        [checked]="f.languages.value.includes(language)"
                        type="checkbox"
                      />
                      <label for="lang-{{ language }}">{{
                        "LANGUE_" + language | uppercase | translate
                      }}</label>
                    </button>
                  </ng-container>
                </div>
              </div>
              <div
                *ngIf="(f.languages.touched || submitted) && f.languages.errors"
                class="invalid-feedback-custom"
              >
                {{ "CHOOSE_LANGUAGE" | translate }}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="d-flex justify-content-between app-dl">
        <button
          class="btn btn-lg btn-primary"
          [attr.aria-label]="'UPDATE' | translate"
          (click)="update()"
          [disabled]="loading"
          type="submit"
        >
          <span *ngIf="!loading">
            {{ "TO_UPDATE" | translate }}
          </span>
          <span *ngIf="loading"
            ><fa-icon
              aria-hidden="true"
              [icon]="['fas', 'circle-notch']"
              [spin]="true"
            >
            </fa-icon>
            {{ "PLEASE_WAIT" | translate }}
          </span>
        </button>
        <a
          [attr.aria-label]="'NAV_BACK_HOMEPAGE' | translate"
          [title]="'NAV_BACK_HOMEPAGE' | translate"
          class="btn btn-lg btn-danger"
          [routerLink]="[routePrefix]"
        >
          {{ "CANCEL" | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
