<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<ng-container *ngIf="isAtLeastOneSourceMustBeDisplayed">
  <div class="source__container px-2 py-1 d-block mx-auto bg-light rounded">
    <span>{{ "SOURCE" | translate }}</span>
    <ng-container *ngFor="let source of sources; let last = last">
      <ng-container *ngIf="source?.toDisplay">
        <strong class="ms-1">{{
          EXTERNAL_SOURCE_MAPPING[source.name].label
        }}</strong>
        <ng-container
          *ngIf="
            EXTERNAL_SOURCE_MAPPING[source.name].licenseLink &&
            EXTERNAL_SOURCE_MAPPING[source.name].licenseLabel
          "
          ><a
            class="ms-1"
            [href]="EXTERNAL_SOURCE_MAPPING[source.name].licenseLink"
            target="_blank"
            >({{ EXTERNAL_SOURCE_MAPPING[source.name].licenseLabel }})</a
          ></ng-container
        >
        <span *ngIf="!last">,</span>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
