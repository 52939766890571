/*
 * Soliguide: Useful information for those who need it
 *
 * SPDX-FileCopyrightText: © 2024 Solinum
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */
import { registerLocaleData } from "@angular/common";
import {
  ALL_SUPPORTED_LANGUAGES,
  AllSupportedLanguagesCode,
} from "@soliguide/common";

async function loadAndRegisterLocale(localeId: string) {
  try {
    const localeModule = await import(
      /* webpackInclude: /\b(fr|ar|en|es|ru|ps|fa|uk|ca|ka)\.mjs/ */
      `../../../../node_modules/@angular/common/locales/${localeId}.mjs`
    );
    registerLocaleData(localeModule.default);
  } catch {
    console.warn("Translation file error found for Angular pipes.");
  }
}

export function registerLocales(): void {
  ALL_SUPPORTED_LANGUAGES.forEach((value: AllSupportedLanguagesCode) => {
    loadAndRegisterLocale(value);
  });
}
