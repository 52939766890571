<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="container">
  <div class="form-container my-3">
    <div class="row align-items-center">
      <div class="col-md-3 p-4">
        <img
          class="img-fluid"
          src="assets/images/partners/jeveuxaider.svg"
          alt="Je veux aider"
        />
      </div>
      <div class="col-md-9">
        <p>
          <a href="https://www.jeveuxaider.gouv.fr/" target="_blank"
            >JeVeuxAider.gouv.fr</a
          >
          est la plateforme publique du bénévolat, proposée par la Réserve
          Civique. Elle met en relation celles et ceux qui veulent agir pour
          l’intérêt général avec les associations, établissements publics et
          communes qui ont besoin de bénévoles. Les missions de bénévolat sont
          ouvertes à toute personne âgée de plus de 16 ans et résidant en
          France, sans condition de nationalité. Ensemble, faisons vivre
          l'engagement de #ChacunPourTous.
        </p>
      </div>
    </div>
  </div>
</div>
<iframe
  title="Devenir bénévole"
  frameborder="0"
  style="display: block; width: 100%; height: 100vh"
  loading="lazy"
  allowfullscreen
  src="https://mission.api-engagement.beta.gouv.fr/?widgetName=SOLINUM"
>
</iframe>

<div style="padding: 10px; display: flex">
  <img
    alt="Logo Je veux aider"
    src="/assets/images/partners/jeveuxaider_short.svg"
  />

  <div
    style="color: #a5a5a5; font-style: normal; font-size: 13px; padding: 8px"
  >
    Proposé par la plateforme publique du bénévolat

    <a href="https://www.jeveuxaider.gouv.fr/" target="_blank"
      >JeVeuxAider.gouv.fr</a
    >
  </div>
</div>
