<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="row my-2" *ngIf="parentFormGroup">
  <div class="col form-group required">
    <label class="form-label" for="password">{{
      "PASSWORD" | translate
    }}</label>
    <div class="input-group">
      <input
        (ngModelChange)="setPassword($event)"
        [(ngModel)]="password"
        name="password"
        [type]="!hidePassword ? 'text' : 'password'"
        class="form-control"
        id="password"
        [ngClass]="{
          'is-invalid':
            (submitted || parentFormGroup.controls.password.dirty) &&
            parentFormGroup.controls.password.errors,
          'is-valid':
            parentFormGroup.controls.password.dirty &&
            !parentFormGroup.controls.password.errors
        }"
        [placeholder]="'PASSWORD' | translate"
        autocomplete="new-password"
        required
      />

      <button
        [attr.aria-label]="
          (hidePassword ? 'Voir' : 'Masquer') + ' le mot de passe'
        "
        type="button"
        class="input-group-text"
        role="switch"
        [attr.aria-checked]="!hidePassword"
        (click)="togglePassword()"
      >
        <fa-icon
          [icon]="hidePassword ? ['fas', 'eye'] : ['fas', 'eye-slash']"
          aria-hidden="true"
        ></fa-icon>
      </button>
    </div>

    <div class="indications">
      <p
        class="my-1"
        [ngClass]="
          parentFormGroup.controls.password.hasError('required') ||
          parentFormGroup.controls.password.hasError('hasNumber')
            ? 'text-danger'
            : 'text-success'
        "
      >
        <fa-icon
          aria-hidden="true"
          [icon]="[
            'fas',
            parentFormGroup.controls.password.hasError('required') ||
            parentFormGroup.controls.password.hasError('hasNumber')
              ? 'times-circle'
              : 'check-circle'
          ]"
        ></fa-icon>
        {{ "ONE_NUMBER" | translate }}
      </p>
      <p
        class="my-1"
        [ngClass]="
          parentFormGroup.controls.password.hasError('required') ||
          parentFormGroup.controls.password.hasError('hasSpecialChar')
            ? 'text-danger'
            : 'text-success'
        "
      >
        <fa-icon
          aria-hidden="true"
          [icon]="[
            'fas',
            parentFormGroup.controls.password.hasError('required') ||
            parentFormGroup.controls.password.hasError('hasSpecialChar')
              ? 'times-circle'
              : 'check-circle'
          ]"
        ></fa-icon>
        {{ "SPECIAL_CHARACTER" | translate }}
        <b
          >&#33;&#34;&#35;&#36;&#37;&#38;&#39;&#40;&#41;&#42;&#43;&#44;&#45;&#46;&#47;&#58;&#59;&#60;&#61;&#62;&#63;&#64;&#91;&#92;&#93;&#94;&#95;&#96;&#123;&#124;&#125;&#126;
        </b>
      </p>
      <p
        class="my-1"
        [ngClass]="
          parentFormGroup.controls.password.hasError('required') ||
          parentFormGroup.controls.password.hasError('hasCapitalCase')
            ? 'text-danger'
            : 'text-success'
        "
      >
        <fa-icon
          [icon]="[
            'fas',
            parentFormGroup.controls.password.hasError('required') ||
            parentFormGroup.controls.password.hasError('hasCapitalCase')
              ? 'times-circle'
              : 'check-circle'
          ]"
        ></fa-icon>
        {{ "ONE_CAPITAL_LETTER" | translate }}
      </p>
      <p
        class="my-1"
        [ngClass]="
          parentFormGroup.controls.password.hasError('required') ||
          parentFormGroup.controls.password.hasError('hasLowerCase')
            ? 'text-danger'
            : 'text-success'
        "
      >
        <fa-icon
          aria-hidden="true"
          [icon]="[
            'fas',
            parentFormGroup.controls.password.hasError('required') ||
            parentFormGroup.controls.password.hasError('hasLowerCase')
              ? 'times-circle'
              : 'check-circle'
          ]"
        ></fa-icon>
        {{ "ONE_LOWER_CASE_LETTER" | translate }}
      </p>
      <p
        class="my-1"
        [ngClass]="
          parentFormGroup.controls.password.hasError('required') ||
          parentFormGroup.controls.password.hasError('minlength') ||
          parentFormGroup.controls.password.hasError('maxlength')
            ? 'text-danger'
            : 'text-success'
        "
      >
        <fa-icon
          aria-hidden="true"
          [icon]="[
            'fas',
            parentFormGroup.controls.password.hasError('required') ||
            parentFormGroup.controls.password.hasError('minlength') ||
            parentFormGroup.controls.password.hasError('maxlength')
              ? 'times-circle'
              : 'check-circle'
          ]"
        ></fa-icon>
        {{ "MUST_CONTAIN_BETWEEN_8_AND_200_CHARACTERS" | translate }}
      </p>
    </div>
  </div>
  <div class="col form-group required">
    <label class="form-label" for="passwordConfirmationInput">{{
      "CONFIRM_YOUR_PASSWORD" | translate
    }}</label>
    <div class="input-group">
      <input
        class="form-control"
        [type]="!hidePasswordConfirmationation ? 'text' : 'password'"
        [ngClass]="{
          'is-invalid':
            (parentFormGroup.controls.passwordConfirmation.dirty ||
              submitted) &&
            (parentFormGroup.controls.passwordConfirmation.errors ||
              parentFormGroup.errors?.noPassswordMatch),
          'is-valid':
            parentFormGroup.controls.passwordConfirmation.dirty &&
            !parentFormGroup.controls.passwordConfirmation.errors &&
            !parentFormGroup.errors?.noPassswordMatch
        }"
        id="passwordConfirmationInput"
        autocomplete="new-password"
        (ngModelChange)="setPasswordConfirmation($event)"
        [(ngModel)]="passwordConfirmation"
        name="passwordConfirmation"
        required
      />

      <button
        type="button"
        class="input-group-text"
        (click)="togglePasswordConfirmation()"
        role="switch"
        [attr.aria-checked]="!hidePasswordConfirmationation"
        [attr.aria-label]="
          (hidePasswordConfirmationation
            ? ('SEE' | translate)
            : ('HIDE' | translate)) + 'PASSWORD_CONFIRMATION' | translate
        "
      >
        <fa-icon
          aria-hidden="true"
          [icon]="
            hidePasswordConfirmationation
              ? ['fas', 'eye']
              : ['fas', 'eye-slash']
          "
        ></fa-icon>
      </button>
    </div>

    <div class="indications">
      <div
        class="text-danger col-md-12"
        *ngIf="
          passwordConfirmation &&
          parentFormGroup.controls.passwordConfirmation.hasError('required')
        "
      >
        {{ "CONFIRM_PASSWORD_MANDATORY" | translate }}
      </div>

      <div
        [ngClass]="
          passwordConfirmation && !parentFormGroup.errors?.noPassswordMatch
            ? 'text-success'
            : 'text-danger'
        "
      >
        <fa-icon
          aria-hidden="true"
          [icon]="[
            'fas',
            passwordConfirmation && !parentFormGroup.errors?.noPassswordMatch
              ? 'check-circle'
              : 'times-circle'
          ]"
        ></fa-icon>
        {{ "PASSWORD_IDENTICAL" | translate }}
      </div>
    </div>
  </div>
</div>
