<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="page-content-header"></div>
<div class="container">
  <div class="page-content page-content-overlay">
    <h1>Avisos legales</h1>
    <p>Asociación Solinum</p>
    <p>
      Ley de asociación de 1901 cuya sede se encuentra en 16 Place des
      Quinconces 33000 Bordeaux.
    </p>
    <p>
      Correo electrónico:
      <a href="mailto:contact@solinum.org">contact@solinum.org</a>
    </p>
    <p>Responsable de la publicación: Pierre Joffre</p>
    <p>
      Este sitio web está alojado en Clever Cloud ubicado en 3 rue de l’Allier,
      44000 Nantes, OVH situado en 2 rue Kellermann, 59100 Roubaix y Scaleway
      situado en 8 rue de la Ville l'Evêque, 75008 Paris, cuyos servidores son
      ubicados dentro de la Unión Europea.
    </p>
  </div>
</div>
