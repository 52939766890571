<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<div class="page-content-header"></div>
<div class="container">
  <div class="page-content page-content-overlay">
    <h1>Mentions légales</h1>
    <p>Association Solinum</p>
    <p>
      Association loi 1901 dont le siège social est situé 16 Place des
      Quinconces 33000 Bordeaux.
    </p>
    <p>Email : <a href="mailto:contact@solinum.org">contact@solinum.org</a></p>
    <p>Responsable de la publication : Pierre Joffre</p>
    <p>
      Ce site web est hébergé par par Clever Cloud situé au 3 rue de l’Allier,
      44000 Nantes, OVH situé 2 rue Kellermann, 59100 Roubaix et Scaleway situé
      au 8 rue de la Ville l’Evêque, 75008 Paris, dont les serveurs sont
      localisés au sein de l’Union européenne.
    </p>
  </div>
</div>
