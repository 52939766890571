<!--
Soliguide: Useful information for those who need it

SPDX-FileCopyrightText: © 2024 Solinum

SPDX-License-Identifier: AGPL-3.0-only

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->
<form [formGroup]="phoneForm" *ngIf="phoneForm">
  <div class="form-group">
    <ngx-intl-tel-input
      [cssClass]="
        phoneForm.controls['phone']?.dirty && phoneForm.controls['phone']?.value
          ? phoneForm.controls['phone']?.errors ||
            phoneForm.controls['phone']?.invalid
            ? 'form-control is-invalid'
            : 'form-control is-valid'
          : phone.label && !phoneForm.controls['phone']?.value
          ? 'form-control is-invalid'
          : 'form-control'
      "
      [preferredCountries]="PREFERRED_COUNTRIES"
      [enableAutoCountrySelect]="false"
      [enablePlaceholder]="true"
      [searchCountryFlag]="true"
      [required]="required"
      [searchCountryPlaceholder]="
        'PHONE_CHOOSE_COUNTRY_PLACEHOLDER' | translate
      "
      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
      [selectedCountryISO]="selectedCountryCode"
      [selectFirstCountry]="true"
      [maxLength]="15"
      [phoneValidation]="true"
      [separateDialCode]="true"
      [inputId]="'phone'"
      [numberFormat]="NgxIntlPhoneNumberFormat.International"
      formControlName="phone"
      name="phone"
    ></ngx-intl-tel-input>
    <p
      *ngIf="
        phoneForm.controls['phone'].value &&
        (phoneForm.controls['phone'].dirty || submitted) &&
        (phoneForm.controls['phone']?.errors?.length ||
          phoneForm.controls['phone']?.invalid)
      "
      class="text-danger my-1"
    >
      {{ "PHONE_NUMBER_IS_INCORRECT" | translate }}
    </p>
  </div>
</form>
